<template>
  
  <div class="section-content pt-0 pr-0">
    
    

    <div class="mr-6" v-if="level === 'Corporate'">
      <HbPropertySelector
      v-if="!this.facilityID  && !isOps"
      :items="facilityList"
      item-text="name"
      item-value="id"
      v-model="property_id"
    />

    <div class="mr-6" :class="{ 'mt-2' : this.property_id }">
      <hb-empty-state 
        v-if="!this.property_id"
        message="Please select a Property to continue."
      ></hb-empty-state>
      </div>

      <hb-notification not-dismissable v-if="property_id && !hasPermission('manage_payment_gateways')" type="caution">You do not have permission to manage payment connections.</hb-notification>

      
      <div class="mr-6 mt-n5">
        <tenant-payments v-if="hasPermission('manage_payment_gateways') && property_id" :application="application" :property_id="property_id" @submitted="fetchApplication"></tenant-payments>
        <credit-cards v-if="property_id" :property_id="property_id" :connection="connections.card" @save="save" @remove="deleteConnection" :merchants="merchants.card" class="mt-n2"></credit-cards>
        <ach v-if="property_id" :property_id="property_id" :connection="connections.ach" @save="save" @remove="deleteConnection" :merchants="merchants.ach" class="mt-n2"></ach>
        <direct-debit v-if="property_id" :property_id="property_id" :connection="connections.directdebit" @save="save" @remove="deleteConnection" :merchants="merchants.directdebit" class="mt-n2"></direct-debit>
      </div>

      <hb-modal
      v-model="deleteModal"
      v-if="deleteModal"
      size="medium"
      title="Delete Payment Connection"
      confirmation
      show-help-link
    >       
        <template v-slot:content>
          <div class="py-4 px-6">
            Are you sure you want to delete the selected payment connection?
            <br /><br />
            <strong>{{ merchantName }} {{ selected.type | capitalize }}</strong>
            <br /><br />
            This action cannot be undone and will result in the loss of stored credit cards. Proceeding will require
            re-collection of credit card numbers from tenants.
          </div>
        </template>
        <template v-slot:actions>
          <hb-btn color="destructive" @click="confirmDelete">
            Delete
          </hb-btn>
        </template>
      </hb-modal>
    </div>
    <div v-else class="mr-6">
      <div v-if="hasPermission('view_sso_page_of_tenant_payments')">  
        <HbPropertySelector
          v-if="!this.facilityID  && !isOps"
          :items="facilityList"
          item-text="name"
          item-value="id"
          v-model="property_id"
        />

          <div class="mr-6" :class="{ 'mt-2' : this.property_id }">
            <hb-empty-state 
              v-if="!this.property_id"
              message="Please select a Property to continue."
            ></hb-empty-state>
          </div>

        <ProcessedPaymentsViewer :property_id="this.property_id"  />
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js'
import { mapGetters, mapActions } from "vuex";
import Status from '../../includes/Messages.vue';
import EmptyStateContainer from '../../assets/EmptyStateContainer.vue'

import Ach from './ach/Index.vue';
import CreditCards from './card/Index.vue';
import TenantPayments from './TenantPayments/Index';
import DirectDebit from './directdebit/Index.vue';
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import { EventBus } from '../../../EventBus.js';
import ProcessedPaymentsViewer from './ProcessedPaymentsViewer.vue';
export default {
  name: 'PaymentConfiguration',
  mixins: [notificationMixin],
  props: {
  level: {
    type: String,
    default: ''
  },
  facilityID: {
    type: String,
    default: ''
  },
  isOps: {
    type: Boolean,
    default: false
  }
},
  data() {
    return {
      property_id: '',
      
      merchants: {
        ach: [
          { label: 'Forte', value: 'forte'},
          { label: 'Tsys', value: 'tsys'},
          { label: 'Tenant Payments', value: 'tenant_payments'},
        ],
        card: [
          { label: 'Authorize.Net', value: 'authorizenet'},
          { label: 'Tsys', value: 'tsys'},
          { label: 'Tenant Payments', value: 'tenant_payments'},
          { label: 'Fat Zebra', value: 'fatzebra'}
        ],
        directdebit: [
          { label: 'Fat Zebra', value: 'fatzebra'}
        ],
      },
      deleteModal: false,
      selected: {},
      application: {},
      connections:{
        ach: {
          id: '',
          name: '',
          property_id: '',
          type: 'ach',
          value: {}
        },
        card: {
          id: '',
          name: '',
          property_id: '',
          type: 'card',
          value: {}
        },
        directdebit: {
          id: '',
          name: '',
          property_id: '',
          type: 'directdebit',
          value: {}
        }
      },
      propertiesList: [],
      currentProperty: {}
    }
  },
  computed: {
    ...mapGetters({
      onboardingData: 'onboardingTabsStore/getOnboardingData',
      hasPermission: 'authenticationStore/rolePermission',
      facilityList: 'propertiesStore/filtered',
      allProperties:'propertiesStore/properties',
      defaultProperty: "propertiesStore/defaultProperty"
    }),

    merchantName(){
      let merchant = this.merchants[this.selected.type].find(m => m.value === this.selected.name);

      return merchant.label;
    },
    
  },

  components: {
    Ach,
    CreditCards,
    TenantPayments,
    DirectDebit,
    ProcessedPaymentsViewer
  },


  watch: {
    property_id() {
      this.setCurrentProperty();
      this.fetchConnections();
      this.fetchApplication();
      this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id))
    }
  },
  async created() {

    this.property_id = this.defaultProperty ? this.defaultProperty.id : "";
    if(this.facilityID) {
      this.property_id = this.facilityID;
    } else {
      this.propertiesList = this.facilityList;
    }
    await this.fetchConnections();
    await this.fetchApplication();
    if(this.isOps) {
      this.property_id = this.onboardingData.property.id;
    }
  },
  methods: {
    ...mapActions({
      fetchTenantPaymentsApplication:
        "tenantPaymentsApplicationStore/fetchTenantPaymentsApplication",
      setDefaultProperty: 'propertiesStore/setDefaultProperty'
    }),
    setCurrentProperty() {
      const property = this.allProperties.find(p => p.id == this.property_id);

      if(property && Object.keys(property).length > 0){
        this.currentProperty = {
          number: property?.number,
          city: property?.Address.city,
          address: property?.Address.address
        }
      }
    },
    async fetchApplication() {
      if (!this.property_id) return;
      this.application = await this.fetchTenantPaymentsApplication({
        property_id: this.property_id,
        current_property: this.currentProperty
      });
    },
    async fetchConnections() {

      if (!this.property_id) return;

      this.connections.ach = {
        id: '',
        name: '',
        property_id: '',
        type: 'ach',
        value: {}
      }
      this.connections.card = {
        id: '',
        name: '',
        property_id: '',
        type: 'card',
        value: {}
      }
      this.connections.directdebit = {
        id: '',
        name: '',
        property_id: '',
        type: 'directdebit',
        value: {}
      }

      let response = await api.get(this, api.PROPERTIES + '/' + this.property_id + '/connections')

      response.connections.map(con => {
        this.$set(this.connections, con.type, con);
      })

      response.connections.forEach(connection => {
        if (connection.Devices && connection.Devices.length > 0 && connection.Devices[0].lan !== undefined) {
          connection.Devices.forEach(function(device) {
            device.lan = device.lan.toString();
          });
        }
      });
    },

    async save (config) {
      try{
        config.property_id = this.property_id;

        let status = await this.validate(this)

        if(!status) return; // throw error?
        console.log("config", config);
        if(config.id){
          let r = await api.put(this, api.PROPERTIES + this.property_id + '/connections/' + config.id, config)
        } else {
          delete config.id;
          let r = await api.post(this, api.PROPERTIES + this.property_id + '/connections', config);
        }
        this.showMessageNotification({ type: 'success', description: 'Payment Configuration saved successfully.' })
        this.fetchConnections()
      } catch(err){
        this.showMessageNotification({ description: err })
      }
    },
    deleteConnection (conn) {
      this.deleteModal = true;

      this.selected = conn;
    },
    async confirmDelete () {
      let r = await api.delete(this, api.PROPERTIES + this.property_id + '/connections/', this.selected.id, 'deleteConfig')
      this.deleteModal = false;
      this.showMessageNotification({ type: 'success', description: 'Payment Configuration deleted successfully.' })
      this.fetchConnections();

    }

  }
}
</script>

<style scoped>
.payment-label {
  background-color: #F9FAFB;
  height: 56px;
  color: #101318;
  display:flex;
  padding-left: 8px;
}

.exp-panel {
  background-color:  #F9FAFB;
}
</style>
